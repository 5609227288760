// ImageArray.js

// Import your specified images
import specifiedImage1 from './images/specific_images/zara_larsson.jpg';
import specifiedImage2 from './images/specific_images/denzel_curry.png';
import specifiedImage3 from './images/specific_images/clara_berry.webp';
import specifiedImage4 from './images/specific_images/clara_berry_2.PNG';
import specifiedImage5 from './images/specific_images/ferarri_scen.PNG';

// ... add more specified images as needed

// Dynamically import the rest of the images
function importAll(r) {
  return r.keys().map(r);
}

const dynamicImages = importAll(
  require.context('./images', false, /\.(png|PNG|JPG|webp)$/)
);

// Combine specified images with dynamic images
const images = [
  specifiedImage1,
  specifiedImage2,
  specifiedImage3,
  specifiedImage4,
  specifiedImage5,
  ...dynamicImages,
];

export default images;
