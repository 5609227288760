// Import the useState hook from React
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from './black-logo.png';
import './Navbar.css';

const Navbar = () => {
  // Use state to track whether the sidebar is open
  const [sidebarOpen, setSidebarOpen] = useState(false);

  // Function to toggle the sidebar state
  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  // Function to close the sidebar
  const closeSidebar = () => {
    setSidebarOpen(false);
  };

  return (
    <div className='background-container'>
      {/* Apply the navbar style dynamically */}
      <div className='navbar-container'>
        <div
          className={`sidebar-icon ${sidebarOpen ? 'open' : ''}`}
          onClick={toggleSidebar}
        >
          <i className={`fas fa-bars ${sidebarOpen ? 'open' : ''}`}></i>
        </div>
        <div className={`sidebar-menu ${sidebarOpen ? 'open' : ''}`}>
          <ul>
            <li>
              <Link to='/Home' onClick={closeSidebar}>
                HOME
              </Link>
            </li>
            <li>
              <Link to='/Magazine' onClick={closeSidebar}>
                MAGAZINE
              </Link>
            </li>
            <li>
              <Link to='/Gallery' onClick={closeSidebar}>
                GALLERY
              </Link>
            </li>
            <li>
              <Link to='/Contact' onClick={closeSidebar}>
                CONTACT
              </Link>
            </li>
          </ul>
        </div>
        <div className={`main-content ${sidebarOpen ? 'shift-right' : ''}`}>
          <div className='navbar'>
            <Link to='/' onClick={closeSidebar}>
              <div className='logo-container'>
                <img src={logo} alt='logo' className='logo-img' />
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
