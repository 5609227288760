// Gallery.js

import React, { useEffect } from 'react';
import ImageComponent from './ImageComponent';
import ImageArray from './ImageArray';
import './Gallery.css';

const Gallery = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts (on page load)
    window.scrollTo(0, 0);
  }, []); // The empty dependency array ensures that this effect runs only once after the initial render

  const lighterSlimItalicArialTextStyle = {
    fontWeight: '400', // Adjust to an even lighter value, like '100' or '200'
    fontFamily: 'Arial, sans-serif', // Use Arial as the font with a fallback to sans-serif
  };

  return (
    <div>
      <div className='gallery-header'>
        <p style={lighterSlimItalicArialTextStyle}>
          A COLLECTION OF PHOTOS FROM OUR PREVIOUS PROJECT called aelmighty
        </p>
        <p style={lighterSlimItalicArialTextStyle}>2017 - 2020</p>
      </div>

      <div className='gallery-container'>
        {ImageArray.map((image, index) => (
          <ImageComponent key={index} image={image} />
        ))}
      </div>
    </div>
  );
};

export default Gallery;
