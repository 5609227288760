import React from 'react';
import './Footer.css'; // Import your CSS file for styling

const Footer = () => {
  return (
    <footer className='footer-container'>
      <div className='icon-container'>
        {/* Use the Font Awesome Instagram icon */}
        <a
          href='https://www.instagram.com/kimnovaq'
          target='_blank'
          rel='noopener noreferrer'
        >
          <i className='fab fa-instagram icon'></i>
        </a>
      </div>
      <p style={{ color: 'black', fontSize: '0.7rem' }}>
        All Rights Reserved, Kim Novaq 2023
      </p>
    </footer>
  );
};

export default Footer;
