import React from 'react';
import './Gallery.css';

const ImageComponent = (props) => {
  return (
    <img src={props.image} className='image-component' alt='img component' />
  );
};

export default ImageComponent;
