import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Navbar from './Navbar/Navbar';
import Magazine from './Magazine/Magazine';
import Contact from './Contact/Contact';
import Footer from './Footer/Footer';
import Home from './Home/Home';
import Gallery from './Gallery/Gallery';

const App = () => {
  return (
    <Router>
      <div className='app-container'>
        <Navbar />

        <div>
          <Switch>
            <Route path='/Magazine' component={Magazine} />
            <Route path='/Gallery' component={Gallery} />
            <Route path='/Contact' component={Contact} />
            <Route path='/' component={Home} />{' '}
            {/* Home component route should be last */}
          </Switch>
        </div>

        <Footer />
      </div>
    </Router>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));

export default App;
