import React from 'react';
import CenteredImage from './transparent_samuel.png'; // Replace with the actual path to your image
import './Home.css';

import logo from './black-logo.png';

const Home = () => {
  return (
    <div>
      <div className='centered-container'>
        <img
          src={CenteredImage}
          alt='Centered Image'
          className='centered-image'
        />
      </div>
    </div>
  );
};

export default Home;
