import React from 'react';

class Magazine extends React.Component {
  render() {
    const isMobile = window.innerWidth <= 767; // Adjust the breakpoint as needed

    // Define the styles based on the screen size
    const containerStyles = {
      position: 'relative',
      width: '100%',
      height: '80vh', // Set a fixed height for the container
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '1.5rem', // Add a margin to move it down
    };

    const iframeStyles = {
      position: 'absolute',
      border: 'none',
      width: '100%',
      height: '100%', // Set iframe height to 100% to fill the container
      left: '0',
      top: '0',
      marginTop: '2.2rem', // Add a margin to move it down
    };

    return (
      <div>
        <div style={containerStyles}>
          <iframe
            style={iframeStyles}
            src='https://online.fliphtml5.com/swblx/chzq/'
            seamless='seamless'
            scrolling='no'
            frameBorder='0'
            allowTransparency='true'
            allowFullScreen='true'
          ></iframe>
        </div>
      </div>
    );
  }
}

export default Magazine;
