import React from 'react';
import './Contact.css';

const Contact = () => {
  return (
    <div className='contact-container contact-background'>
      <div className='contact-text-container'>
        <div>
          <p className='centered-text'>
            Kim Novaq, formerly known as Aelmighty
          </p>
          <p className='centered-text'>
            Embracing the essence of Nordic avant-garde creativity
          </p>
          <p className='centered-text'>
            For any inquiries, contact info@kimnovaq.com
          </p>
        </div>
      </div>
    </div>
  );
};

export default Contact;
